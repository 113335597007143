import buildDynamicFacets from "./buildDynamicFacets"
import StaticFacetFactory from "./staticFacetFactory"

class FacetParser {
  constructor() {
    this.staticFacets = new StaticFacetFactory()
    this.staticFacets.register([
      {
        groupName: "Fragrance Details",
        facets: {
          BlendingElements: "blending_elements",
          CleanScents: "clean_scent",
          "Best for Soy Wax": "best_for_soy_wax",
          "Phthalate Free": "phthalate_free",
          "Prop 65 Free": "prop_65_free",
          "Shippable By Air": "shippable_by_air",
          "Vanillin Free": "vanillin_free",
        },
      },
      {
        groupName: "Application",
        facets: {
          "Diffuser Base Approved": "diffuser_base_approved",
          "Lotion Safe": "lotion_safe",
          "Soap Safe": "soap_safe",
          "Room/Linen Spray": "room_linen_spray",
        },
      },
      {
        groupName: "Soap Making",
        facets: {
          "No Acceleration": "no_acceleration",
          "No Discoloration": "no_discoloration",
        },
      },
    ])
  }

  parse(facetsWithCounts) {
    this.staticFacets.updateCounts(facetsWithCounts)
    return [
      {
        groupName: "Seasons",
        facets: buildDynamicFacets({
          facetCounts: facetsWithCounts,
          groupName: "searchable_seasons",
        }),
      },
      {
        groupName: "Categories",
        facets: buildDynamicFacets({
          facetCounts: facetsWithCounts,
          groupName: "searchable_categories",
        }),
      },
      {
        groupName: "Fragrance Notes",
        facets: buildDynamicFacets({
          facetCounts: facetsWithCounts,
          groupName: "searchable_notes",
        }),
      },
      {
        groupName: "Fragrance Details",
        facets: this.staticFacets.getGroup("Fragrance Details"),
      },
      {
        groupName: "Application",
        facets: this.staticFacets.getGroup("Application"),
      },
      {
        groupName: "Soap Making",
        facets: this.staticFacets.getGroup("Soap Making"),
      },
    ]
  }
}

export default FacetParser
