import BaseFacet from "./baseFacet"

class StaticFacet extends BaseFacet {
  constructor({ displayName, propertyName, groupName }) {
    super({ displayName, propertyName, groupName })
    this.facetCount = 0
  }

  get filterValue() {
    return `${this.propertyName}:true`
  }

  set count(facetCount) {
    this.facetCount = facetCount
  }

  get count() {
    return this.facetCount
  }

  inGroup(groupName) {
    return this.groupName === groupName
  }
}

export default StaticFacet
