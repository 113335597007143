import DynamicFacet from "./dynamicFacet"

function buildDynamicFacets({ facetCounts, groupName }) {
  const rawFacetHits = facetCounts[groupName] || {}
  const groupFacets = []

  Object.entries(rawFacetHits).forEach(([displayName, facetCount]) => {
    const facet = new DynamicFacet({
      groupName,
      displayName,
      facetCount,
    })
    groupFacets.push(facet)
  })
  return groupFacets
}

export default buildDynamicFacets
