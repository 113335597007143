<template>
  <AllTheOils v-if="current1ozSale" :h-padding="true" />
  <Container>
    <ScentGenie />
  </Container>
  <Container>
    <FilterRow
      v-if="Object.values(selectedFilters).length > 0"
      :selected-filters="selectedFilters"
      @clear-all="clearAll"
      @toggle-filter="toggleFilter"
    />

    <div class="fragrance-finder">
      <div class="fragrance-finder-head">
        <Button
          layout="inverted"
          size="small"
          color="navy"
          class="mini-only"
          @click="toggleMenu"
        >
          Filters
          <span v-if="Object.values(selectedFilters).length > 0"
            >({{ Object.values(selectedFilters).length }})</span
          >
        </Button>
        <span class="fragrance-finder-results">
          {{ resultsCountText }}
        </span>
      </div>
      <label class="fragrance-finder-info-select_wrap">
        <Select
          class="fragrance-finder-info-select"
          :model-value="searchClient.currentIndexName"
          @update:model-value="changeSearchIndex"
        >
          <option
            v-for="option in searchIndicesForSelect"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </Select>
      </label>
      <div
        :class="[
          'fragrance-finder-sidebar',
          mobileMenuOpen && 'fragrance-finder-sidebar-open',
        ]"
      >
        <div class="fragrance-finder-sidebar-head">
          <h2>Filter</h2>
          <Button size="small" color="gray" @click="toggleMenu">Close</Button>
        </div>
        <FacetSidebar
          :loading="loading && !anyResults"
          :facet-groups="facetGroups"
          :selected-filters="selectedFilters"
          @toggle-filter="toggleFilter"
        />
        <div class="fragrance-finder-sidebar-buttons">
          <Button
            size="small"
            :rounded-corners="false"
            :disabled="Object.keys(selectedFilters).length === 0"
            @click="clearAll"
          >
            Reset All Filters
          </Button>
          <Button
            color="gray"
            size="small"
            :rounded-corners="false"
            class="mini-only"
            @click="toggleMenu"
          >
            Done
          </Button>
        </div>
      </div>

      <div class="fragrance-oil-finder-grid">
        <template v-if="loading && !anyResults">
          <div
            v-for="skeleton in [...Array(30)]"
            :key="skeleton"
            class="skeleton"
          />
        </template>
        <template v-else>
          <ProductThumb
            v-for="fragrance in fragrances"
            :id="fragrance.objectID"
            :key="fragrance.objectID"
            :src="fragrance.image"
            :srcset="fragrance.image_src_set"
            :price-range="[fragrance.min_price, fragrance.max_price]"
            :href="urlFor(fragrance)"
            :clean-scent-level="fragrance.clean_scent_level"
            :is-new="fragrance.new_product"
            :name="fragrance.name"
            :alt="fragrance.name"
            :rating="Math.round(fragrance.rating)"
            :is-clean-scent="fragrance.clean_scent"
            :is-blending-element="fragrance.blending_elements"
            sizes="175px"
          />
        </template>
      </div>
    </div>
  </Container>
</template>

<script setup>
  import AlgoliaClient from "./algoliaClient"
  import searchOptions from "./algoliaConfig"
  import FacetParser from "./facetParser"
  import FacetSidebar from "./facetSidebar"
  import FilterRow from "./filterRow"
  import ProductThumb from "~/components/ProductThumb"
  import Container from "~/components/Container"
  import Select from "~/components/Select"
  import Button from "~/components/Button"

  const saleStore = useSaleStore()
  const { $config, $mq, $router } = useNuxtApp()

  const current1ozSale = computed(() => saleStore.activeSale)

  const props = defineProps({
    searchIndexNames: {
      type: Array,
      required: true,
    },
  })

  const searchClient = new AlgoliaClient({
    algoliaAppId: $config.public.ALGOLIA_APPLICATION_ID,
    algoliaApiKey: $config.public.ALGOLIA_API_KEY,
    searchIndexNames: props.searchIndexNames,
    searchOptions,
  })

  onBeforeRouteUpdate(() => {
    search()
  })

  const mobileMenuOpen = ref(false)
  const loading = ref(false)

  const fragrances = ref([])
  const selectedFilters = ref({})
  const facetGroups = ref([])
  const facetParser = new FacetParser()

  const resultsCountText = computed(() => {
    const count = fragrances.value.length
    if (count === 1) {
      return "1 fragrance"
    }
    return `${count} fragrances`
  })

  const searchIndices = computed(() => Object.keys(searchClient.searchIndices))
  const searchIndicesForSelect = computed(() => {
    const result = []
    searchIndices.value.forEach((si) => {
      result.push({
        label: si,
        value: si,
      })
    })
    return result
  })

  const anyResults = computed(() => fragrances.value.length > 0)

  const search = () => {
    if (!import.meta.client) {
      return
    }
    loading.value = true
    searchClient
      .search({
        filters: Object.values(selectedFilters.value).join(" AND "),
      })
      .then(
        (content) => {
          fragrances.value = content.hits
          facetGroups.value = facetParser.parse(content.facets)
        },
        (error) => {
          console.error(error)
        },
      )
      .finally(() => {
        loading.value = false
      })
  }

  const freezePage = () => {
    if ($mq.mini.value) {
      const html = document.querySelector("body")
      const noScrollClass = "fragrance-finder-no-scroll"

      if (mobileMenuOpen.value) {
        html.classList.add(noScrollClass)
      } else {
        html.classList.remove(noScrollClass)
      }
    }
  }

  const getParamsFromUrl = () => {
    const PARAMETER_IGNORELIST = [
      "order",
      "_kx",
      "utm_medium",
      "utm_source",
      "utm_term",
      "utm_campaign",
      "utm_content",
      "nb_klid",
    ]

    const queryParams = $router.currentRoute.value.query
    const filteredParameters = Object.entries(queryParams).reduce(
      (acc, [key, value]) => {
        if (!PARAMETER_IGNORELIST.includes(key)) {
          acc[key] = value
        }
        return acc
      },
      {},
    )

    if (Object.keys(filteredParameters).length === 0) {
      selectedFilters.value = {}
    } else {
      selectedFilters.value = filteredParameters
    }
  }

  const toggleMenu = () => {
    mobileMenuOpen.value = !mobileMenuOpen.value
  }

  const updateRoutes = () => {
    $router.push({
      query: {
        ...selectedFilters.value,
      },
    })
  }
  const clearAll = () => {
    selectedFilters.value = {}
    updateRoutes()
  }

  const changeSearchIndex = (value) => {
    searchClient.searchIndex = value
    updateRoutes()
    search()
  }

  const toggleFilter = (event) => {
    if (selectedFilters.value[event.displayName]) {
      const filteredFilters = Object.entries(selectedFilters.value).reduce(
        (acc, [key, value]) => {
          if (key !== event.displayName) {
            acc[key] = value
          }
          return acc
        },
        {},
      )
      selectedFilters.value = filteredFilters
    } else {
      selectedFilters.value = {
        [event.displayName]: event.filterValue,
        ...selectedFilters.value,
      }
    }
    updateRoutes()
  }

  const urlFor = (fragrance) => {
    return fragrance.url_path || `/${fragrance.custom_url}/`
  }

  watch(selectedFilters, () => search())
  watch(searchIndices, () => search())
  watch(mobileMenuOpen, () => freezePage())

  onMounted(() => {
    getParamsFromUrl()
    search()
  })
</script>

<style lang="scss">
  .fragrance-finder-no-scroll {
    @media (max-width: 660px) {
      max-height: 100vh;
      overflow: hidden;
    }
  }
</style>
<style lang="scss" scoped>
  @keyframes load {
    50% {
      opacity: 0.7;
    }
  }
  .fragrance-finder {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: $space-m;
    @include viewport("sm") {
      grid-template-columns: 200px 1fr;
    }
    @include viewport("mini") {
      display: flex;
      flex-direction: column;
    }
  }
  .fragrance-finder-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .fragrance-oil-finder-grid {
    display: grid;
    grid-gap: 16px;
    align-content: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
    @include viewport("mini") {
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    }
  }
  // Siderbar
  .fragrance-finder-sidebar {
    transition:
      transform 180ms ease-in-out,
      opacity 180ms ease-in-out;

    @include viewport("mini") {
      box-sizing: border-box;
      z-index: 100;
      position: fixed;
      left: 0;
      top: 0;
      background-color: white;
      padding: $space-s;
      height: 100vh;
      overflow-y: scroll;
      transform: translateX(-100vw);
      opacity: 0;
    }

    &-open {
      @include viewport("mini") {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
  .fragrance-finder-sidebar-head {
    display: none;
    align-items: center;
    justify-content: space-between;
    @include viewport("mini") {
      display: flex;
    }
  }
  // Fragrance Info

  .fragrance-finder-results {
    display: inline-block;
    font-weight: bold;
    padding: 0;
  }

  .fragrance-finder-info-select_wrap {
    display: flex;
    align-items: center;
    flex-grow: 0;
    justify-self: flex-end;
    grid-gap: $base-spacing * 2;
    &-label {
      font-weight: bold;
      white-space: nowrap;
    }
    @include viewport("mini") {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .fragrance-finder-info-select {
    max-width: 200px;
    @include viewport("mini") {
      max-width: none;
    }
  }

  .fragrance-finder-sidebar-buttons {
    display: flex;
    padding: $space-s 0;
    grid-gap: $space-s;
  }

  .skeleton {
    aspect-ratio: 0.68;
    background: $gray-200;
    border-radius: $border-radius;
    animation: load 1.5s ease-in-out infinite;
  }

  .mini-only {
    display: none;
    @include viewport("mini") {
      display: flex;
    }
  }
</style>
