/**
 * BaseFacet is an abstract class
 * A concrete subclass needs to implement a
 * filterValue property
 */
class BaseFacet {
  constructor({ displayName, propertyName, groupName }) {
    this.displayName = displayName
    this.propertyName = propertyName
    this.groupName = groupName
  }

  get id() {
    return this.displayName
  }
}

export default BaseFacet
