export default {
  maxValuesPerFacet: 500,
  sortFacetValuesBy: "alpha",
  hitsPerPage: 1000,
  attributesToRetrieve: [
    "objectID",
    "name",
    "custom_url",
    "url_path",
    "image",
    "image_src_set",
    "vanillin_free",
    "phthalate_free",
    "shippable_by_air",
    "diffuser_base_approved",
    "room_linen_spray",
    "min_price",
    "max_price",
    "display_price",
    "soy_performance",
    "best_for_soy_wax",
    "rating",
    "soap_safe",
    "lotion_safe",
    "no_acceleration",
    "no_discoloration",
    "clean_scent",
    "clean_scent_level",
    "new_product",
    "blending_elements",
  ],
  facets: [
    "prop_65_free",
    "diffuser_base_approved",
    "room_linen_spray",
    "shippable_by_air",
    "lotion_safe",
    "phthalate_free",
    "searchable_categories",
    "searchable_notes",
    "searchable_seasons",
    "soap_safe",
    "vanillin_free",
    "best_for_soy_wax",
    "no_acceleration",
    "no_discoloration",
    "clean_scent",
    "blending_elements",
  ],
}
