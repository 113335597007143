<template>
  <div class="facet-sidebar">
    <template v-if="loading">
      <div
        v-for="skeleton in [...Array(5)]"
        :key="skeleton"
        class="facet-skeleton"
      >
        <div class="facet-skeleton-title" />
        <div class="facet-skeleton-tags">
          <div
            v-for="skeletonTag in [...Array(5)]"
            :key="skeletonTag"
            class="facet-skeleton-tag"
          />
        </div>
      </div>
    </template>
    <div
      v-for="facetGroup in facetGroups"
      :key="facetGroup.groupName"
      :class="[FACET_CLASS_MAP[facetGroup.groupName], 'facet-group']"
      @toggle-filter="onToggleFilter"
    >
      <template v-if="facetGroup.facets.length > 0">
        <h3 class="facet-group-title">
          {{ facetGroup.groupName }}
        </h3>
        <div class="facet-siderbar-facets">
          <button
            v-for="facet in facetGroup.facets"
            :key="facet.id"
            :class="[
              Object.keys(selectedFilters).includes(facet.displayName) &&
                'active',
              'facet-sidebar-button',
            ]"
            :aria-pressed="
              Object.keys(selectedFilters).includes(facet.displayName)
            "
            @click="
              onToggleFilter({
                displayName: facet.displayName,
                filterValue: facet.filterValue,
              })
            "
          >
            {{ facet.displayName }}
            <span class="facet-sidebar-button-count">
              {{ facet.facetCount }}
            </span>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  const FACET_CLASS_MAP = {
    Seasons: "facet-group-seasons",
    Categories: "facet-group-categories",
    "Fragrance Notes": "facet-group-fragrance-notes",
    "Fragrance Details": "facet-group-fragrance-details",
    Application: "facet-group-application",
    "Soap Making": "facet-group-soap-making",
  }

  export default {
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      facetGroups: {
        type: Array,
        required: true,
      },
      selectedFilters: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        FACET_CLASS_MAP,
      }
    },
    methods: {
      onToggleFilter(payload) {
        this.$emit("toggle-filter", payload)
      },
      toggleFilter() {
        this.$emit("toggle-filter", {
          displayName: this.facet.displayName,
          filterValue: this.facet.filterValue,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @keyframes load {
    50% {
      opacity: 0.7;
    }
  }
  .facet-sidebar {
    border-top: 2px solid $gray-100;
    @include viewport("mini") {
      border-top: none;
    }
  }
  .active {
    background: gray;
  }

  .facet-siderbar-facets {
    display: flex;
    flex-flow: row wrap;
    grid-gap: 4px;
    padding-bottom: $space-s;
    border-bottom: 2px solid $gray-100;

    .facet-group-fragrance-notes & {
      max-height: 300px;
      overflow-y: auto;
    }
  }

  .facet-sidebar-button {
    padding: 1ex 1em;
    font-weight: bold;
    @apply text-sm leading-normal;
    background: $blue-tint;
    color: $blue-text;
    border: none;
    border-radius: $border-radius;
    &:hover {
      background: adjust-color($blue-tint, $lightness: -20%);
    }
    &.active {
      color: white;
      background: $blue-800;
    }
  }

  .facet-sidebar-button-count {
    margin-left: $base-spacing;
    font-weight: normal;
  }
  .facet-group-title {
    font-weight: bold;
  }

  // Skeleton
  .facet-skeleton {
    border-bottom: 2px solid $gray-100;
  }
  .facet-skeleton-title {
    margin: 1em 0;
    width: $base-spacing * 20;
    border-radius: $border-radius;
    height: $base-spacing * 6.5;
    background: $gray-200;
    animation: load 1.5s ease-in-out infinite;
  }
  .facet-skeleton-tags {
    display: flex;
    grid-gap: $base-spacing;
    flex-flow: row wrap;
    padding-bottom: $base-spacing * 4;
  }
  .facet-skeleton-tag {
    animation: load 1.5s ease-in-out infinite;

    flex-shrink: 0;
    width: $base-spacing * 24;
    border-radius: $border-radius;
    height: $base-spacing * 6.5;
    background: $gray-100;
  }
</style>
