import StaticFacet from "./staticFacet.js"

class StaticFacetFactory {
  constructor() {
    this.facets = []
  }

  register(facetGroups) {
    facetGroups.forEach(({ groupName, facets }) => {
      Object.entries(facets).forEach(([displayName, propertyName]) => {
        const facet = new StaticFacet({ displayName, propertyName, groupName })
        this.facets.push(facet)
      })
    })
  }

  getGroup(groupName) {
    const group = []
    this.facets.forEach((facet) => {
      if (facet.inGroup(groupName)) {
        group.push(facet)
      }
    })
    return group
  }

  updateCounts(facetsWithCount) {
    this.facets.forEach((facet) => {
      const facetUpdate = facetsWithCount[facet.propertyName]
      if (facetUpdate && facetUpdate.true) {
        facet.count = facetUpdate.true
      } else {
        facet.count = 0
      }
    })
  }
}

export default StaticFacetFactory
