<template>
  <ClientOnly>
    <FragranceFinder :search-index-names="searchIndexNames" />
  </ClientOnly>
</template>

<script>
  const searchIndexNames = [
    {
      displayName: "Alphabetical",
      algoliaName: "Cs_Fragrance",
      isDefault: true,
    },
    {
      displayName: "Newest",
      algoliaName: "Cs_Fragrance_by_newest",
    },
    {
      displayName: "Price: Low to High",
      algoliaName: "Cs_Fragrance_by_price",
    },
    {
      displayName: "Highest Rated",
      algoliaName: "Cs_Fragrance_by_reviews",
    },
  ]
  export default {
    setup() {
      useHead({
        title: "Fragrance Oil Finder",
      })
    },
    data() {
      return { searchIndexNames }
    },
  }
</script>
