import BaseFacet from "./baseFacet"

class DynamicFacet extends BaseFacet {
  constructor({ displayName, groupName, facetCount }) {
    super({
      displayName,
      propertyName: displayName,
      groupName,
    })
    this.facetCount = facetCount
  }

  get filterValue() {
    return `${this.groupName}:"${this.propertyName}"`
  }

  get count() {
    return this.facetCount
  }
}

export default DynamicFacet
